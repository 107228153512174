// log
import store from "../store";
import Web3 from "web3";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {

      console.log("Fetch From Contract");

      let totalSupply = 0;
      let price = Web3.utils.toWei('0.0', 'ether');
      let isWhiteList = true;
      let isPaused = true;
      let minterMaxAmount = 0;
      let minterSignature = "0x";

      totalSupply = await store
        .getState()
        .blockchain.smartContract.methods.totalSupply()
        .call();

      isWhiteList = await store
        .getState()
        .blockchain.smartContract.methods.isWhiteList()
        .call();

      isPaused = await store
        .getState()
        .blockchain.smartContract.methods.isPaused()
        .call();

      if(isPaused) 
      {
        price = Web3.utils.toWei('0.0', 'ether');
      }
      else
      {
        if (isWhiteList) 
        {
          price = Web3.utils.toWei('0.065', 'ether');
        }
        else
        {
          price = Web3.utils.toWei('0.09', 'ether');
        }
      }

      if (isWhiteList) 
      {
        var account = await store.getState().blockchain.account;

        console.log("Searching white list for:", account.toLowerCase());

        const whitelistResponse = await fetch(`/config/whitelist.json?tick=${Date.now()}`, {
          headers: { "Content-Type": "application/json", Accept: "application/json" },
        });
    
        // Parse the white list.
        const parsed = await whitelistResponse.json();

        // Get the white list entry for the current wallet.
        var entry = parsed[account.toLowerCase()];
  
        // Check if we have a whitelist entry.
        if (entry == null) {
  
          // This code is temporary, need to display something proper to the user.
          console.log('Not on white list!');
  
          minterMaxAmount = 0;
          minterSignature = "0x";

        } else {
          minterMaxAmount = entry.max;
          minterSignature = entry.signature;
        }

      }
      else
      {
        minterMaxAmount = 6;
        minterSignature = "0x";
      }

      console.log("Supply:", totalSupply);
      console.log("Price:", price);
      console.log("Is White List:", isWhiteList);
      console.log("Is Paused:", isPaused);
      console.log("Minter Max Amount:", minterMaxAmount);
      console.log("Minter Signature:", minterSignature);

      dispatch(
        fetchDataSuccess({
          totalSupply,
          price,
          isWhiteList,
          isPaused,
          minterMaxAmount,
          minterSignature
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
